// Build toastr notification for incomming call
function toastr_phoneStart(phone) {
    start_at = moment(phone.start_at.date).format('DD/MM/YYYY, HH:mm');
    var html = `
        <div class='toast-date'>${start_at}</div>
        <div class='toast-callerno'>${phone.callerno}</div>
    `;

    if (phone.voters.length > 0) {
        phone.voters.forEach(element => {
            html += `<div class='toast-voters'><a href="/voters/${element.id}">${element.fullanme}</a></div>`
        });
    }
    else {
        html += `<div class='toast-unknown'>(ΑΓΝΩΣΤΟΣ)</div>`
    }
    

    return html;
}

// Build toastr notification for call in progress
function toastr_phoneAnswered(phone) {
    start_at = moment(phone.start_at.date).format('DD/MM/YYYY, HH:mm');
    var html = `
        <div class='toast-talking'>Συνομιλία σε εξέλιξη ...</div>
        <div class='toast-date'>${start_at}</div>
        <div class='toast-callerno'>${phone.callerno}</div>
    `;

    if (phone.voters.length > 0) {
        phone.voters.forEach(element => {
            html += `<div class='toast-voters'><a href="/voters/${element.id}">${element.fullanme}</a></div>`
        });
    }
    else {
        html += `<div class='toast-unknown'>(ΑΓΝΩΣΤΟΣ)</div>`
    }
    

    return html;
}

module.exports = {
    toastr_phoneStart,
    toastr_phoneAnswered
};